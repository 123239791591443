<template>
    <section class="tables">
        <div class="page-header">
            <h3  class="page-title">
                Score for {{ room.name }}
            </h3>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title"></h4>
                        <div class="float-right">
                            <!-- search field -->
                            <!-- <b-input v-model="filter" placeholder="Search Job" id="job-search" style="padding: 10px"></b-input> -->
                        </div>
                        <b-table :items="items" id="table-list" responsive :per-page="perPage" :current-page="currentPage"
                            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty :filter="filter"
                            striped hover>

                            <!-- when no item found -->
                            <template #empty="">
                                <p class="text-muted text-center">{{ noItems }}</p>
                            </template>

                            <template v-slot:cell(action)="data">
                                <!-- Actions -->

                                <i v-b-modal.modallg v-b-tooltip.html.top title="View Details" variant="primary"
                                    @click="viewDetail(data.item.id)" :ref="'btn' + data.index"
                                    class="mr-2 mdi mdi-eye text-p1 icon-sm"></i>

                                <span v-html="data.value"></span>
                            </template>
                        </b-table>
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="table-list" align="right">
                        </b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";


Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="mdi mdi-arrow-down"></i>',
    descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
    data: function () {
        return {
            sortBy: 'name',
            perPage: 10,
            currentPage: 1,
            sortDesc: false,
            sortByFormatted: true,
            filterByFormatted: true,
            filter: "",
            sortable: true,
            fields: [
                { key: 'room', sortable: true },
                { key: 'player', sortable: true },
                { key: 'score', sortable: true },
                { key: 'createdAt', sortable: true },
                { key: 'updatedAt', sortable: true },
            ],
            items: [],
            noItems: null,
            room: {}
        };
    },
    computed: {
        ...mapGetters(['getScore']),
        rows() {
            return this.items.length
        }
    },
    methods: {
        ...mapActions(['setScore']),
        setItems(data) {
            data.forEach((element) => {
                let obj = {};
                obj.id = element.id;
                obj.room = element.room?.name;
                obj.player = element.player.firstName + element.player.lastName;
                obj.score = element.score;
                obj.createdAt = moment(element.createdAt).format('ll');
                obj.updatedAt = moment(element.updatedAt).format('ll');

                this.items.push(obj);
            });
        },
        viewDetail(id) {
        }
    },
    async created(){
        this.$route.params.roomId == undefined ? this.$router.push({ name: 'dashboard' }) : null;
    },
    
    async mounted() {
        let response = await this.setScore({ roomId: this.$route.params.roomId });
        this.room = response.data.room;
        console.log(this.getScore);
        this.getScore.length > 0
            ? this.setItems(this.getScore)
            : (this.noItems = "No Score Found.");
    }
}
</script>
    